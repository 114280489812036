.ProjectsCardHeader {
    font-size: 125%;
    text-align: left;
    text-shadow: none;
}

.ProjectsCardHeaderSubtitle
{
    font-size: 100%;
    text-align: left;
    text-shadow: none;
}

#GoldMedal {
    height: 2.5vmin;
}

#BronzeMedal {
    height: 2.5vmin;
}

.CardText {
    margin: 2vh 0;
    text-align: justify;
    text-shadow: none;
}

.Credits {
    font-size: 75%;
    text-shadow: none;
}

.card-img-top {
    width: 100%;
    height: 20vh;
    object-fit: contain;
    background-color: gray;
}