body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.App {
    height: 100vh;
    text-align: center;
    background-image: url(/static/media/what-the-hex.6633a16d.png);
    background-repeat: repeat;
    overflow: auto;
}

#brand {
    background: radial-gradient(white 25%, black);
    border-radius: 50%;
}

.SocialLinks {
    height: 3vh;
}

a {
    color: white;
    text-decoration: none;
    margin: 0 1vmin;
    font-style: bold;
}

a:hover,
.DropMenu a:hover
{
    text-decoration: none;
    color: orange;
}

a.active,
.DropMenu a:active
{
    color: lightblue;
}

.DropMenu {
    background-color: gray;
    width: auto;
}

.Headshot {
    height: 20vh;
    border-radius: 50%;
}

.NameBox {
    background: lightgray;
}

p {
    text-shadow: 0.25vmin 0.25vmin lightgray;
    margin: 0.1vmin;
}

.NameLine {
    font-size: 225%;
    font-family: "Arial Black", Gadget, sans-serif;
    font-style: bold;
}

.SubTitle {
    font-size: 175%;
    font-family: "Times New Roman", Times, serif;
    font-style: italic;
    color: gray;
}

.NameLineMedium {
    font-size: 200%;
    font-family: "Arial Black", Gadget, sans-serif;
    font-style: bold;
}

.SubTitleMedium {
    font-size: 150%;
    font-family: "Times New Roman", Times, serif;
    font-style: italic;
    color: gray;
}

.NameLineSmall {
    font-size: 125%;
    font-family: "Arial Black", Gadget, sans-serif;
    font-style: bold;
}

.SubTitleSmall {
    font-size: 100%;
    font-family: "Times New Roman", Times, serif;
    font-style: italic;
    color: gray;
}

.AboutMe {
    margin: 2vh 0;
    font-style: oblique;
    text-align: justify;
    text-shadow: none;
}
.photo-subcaption {
    text-shadow: none;
}

.gallery-content {
    /* margin: 30px 0 0 0; */
}
.ProjectsCardHeader {
    font-size: 125%;
    text-align: left;
    text-shadow: none;
}

.ProjectsCardHeaderSubtitle
{
    font-size: 100%;
    text-align: left;
    text-shadow: none;
}

#GoldMedal {
    height: 2.5vmin;
}

#BronzeMedal {
    height: 2.5vmin;
}

.CardText {
    margin: 2vh 0;
    text-align: justify;
    text-shadow: none;
}

.Credits {
    font-size: 75%;
    text-shadow: none;
}

.card-img-top {
    width: 100%;
    height: 20vh;
    object-fit: contain;
    background-color: gray;
}
