.App {
    height: 100vh;
    text-align: center;
    background-image: url("assets/what-the-hex.png");
    background-repeat: repeat;
    overflow: auto;
}

#brand {
    background: radial-gradient(white 25%, black);
    border-radius: 50%;
}
